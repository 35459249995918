import { IncomingMessage } from "http";
import { DomainForm } from "pages/api/domainForm";
import {
    Dispatch,
    HTMLInputTypeAttribute,
    ReactElement,
    SetStateAction,
} from "react";
import {
    Control,
    FieldValues,
    UseFormRegister,
    UseFormSetValue,
    UseFormWatch,
} from "react-hook-form";
import {
    GQLAttachments,
    GQLCategories,
    GQLDkis,
    GQLDomainCategories,
    GQLDomainForms,
    GQLDomainMetatags,
    GQLDomainOffers,
    GQLDomainRoutes,
    GQLForms,
    GQLListings,
    GQLMenus,
    GQLOffers,
    GQLPosts,
    GQLTextBlocks,
    GQLTextBlocksQuery,
    GQLTranslations,
    GQLUrls,
    Maybe,
    Scalars,
} from "@gql/types/gql";
import {
    AllFieldsDependency,
    ErrorField,
    FieldDependency,
    FormErrors,
    Regulations,
} from "./form-store-types";
import { DomainOffer } from "@components/shared/accordion/category-item";
export type ServerRequest = IncomingMessage & {
    cookies: Partial<{ [key: string]: string }>;
} & {
    accessToken: string | null;
    locale: string | null;
    defaultLocale: string | null;
    locales: null | string[];
    isBlocked: boolean;
};
export interface ItemOption {
    id: number;
    value: string | number;
    label: string | number;
    icon?: null | Pick<Image, "fullPath">;
}
export interface FieldOption {
    label: string;
    selected: boolean;
    value: string;
}
export interface SelectConfig {
    [x: string]: string | boolean | undefined;
}
export interface StepInterface {
    id: number;
    stepTitle: string;
    name: string;
    imgPath: string;
    description: string;
    codeName?: string;
    input: {
        type: string;
        label: string;
        multiSelect?: boolean | undefined;
        options?: ItemOption[] | undefined;
    };
}

export interface Item {
    id: number;
    label: string;
    value: number | number;
}
export interface FormSelect {
    placeholder?: string | null;
    disabled?: boolean;
    register?: UseFormRegister<{}>;
    items?: string[] | ItemOption[] | FieldOption[] | Option[] | undefined;
    onChange?: (val?: string) => void;
    multiple?: boolean | undefined;
    field: Field;
    className?: string;
    icon?: ReactElement;
    loading?: boolean;
    style?: React.CSSProperties | undefined;
    codeName?: string;
    formId: number;
    watch?: UseFormWatch<FieldValues>;
    action?: (data?: any) => void;
    setValue: UseFormSetValue<{}>;
    dependenciesValues?: FieldDependency | undefined;
    setFieldChanged: Dispatch<SetStateAction<boolean>>;
    isCalculatedYears: boolean;
    questionAsTitle: boolean | undefined;
    isFirstStep: boolean;
}
export interface FieldDataDependency {
    source: "lookups" | "variations";
    type: string;
    ordering:
        | undefined
        | {
              field: "order" | "label";
              direction: "asc" | "desc";
          };
    dependency:
        | null
        | {
              fieldCodeName: string;
              dependentOn: "lookups" | "variations";
              type: string;
          }[];
}
export interface Origin {
    id: number;
    label: string;
    codeName: string;
    defaultValues: {
        options: ItemOption[];
    } | null;
    minValue: MinMaxValue | null;
    maxValue: MinMaxValue | null;
    userId: string;
    fieldType: FieldTypes;
    validationType:
        | "zipCode"
        | "firstName"
        | "lastName"
        | "email"
        | "phoneNumber"
        | "companyName"
        | "streetAddress";
    placeholder: string | null;
    note: string | null;
    autocomplete: string | null;
    defaultValue: string | null;
    pattern: string;
    mask: null | "us-phone" | "zipCode";
    title: string | null;
    niceName: string | null;
    meta: null | { [key in FieldMetaKeys]: string }[];
    dataDependency: null | FieldDataDependency;
    errorMessage: string | null;
}
export interface Field {
    label: string | null;
    defaultValue: string | null;
    defaultValues: {
        options: ItemOption[];
    } | null;
    dataDependency: null | FieldDataDependency;
    validationType:
        | "zipCode"
        | "firstName"
        | "lastName"
        | "email"
        | "phoneNumber"
        | "companyName"
        | "streetAddress";

    fieldType: FieldTypes;
    placeholder: string | null;
    isRequired: boolean;
    pattern: string;
    // pattern: ValidationRule<RegExp> | null;
    errorMessage: string | null;
    minValue: MinMaxValue | null;
    maxValue: MinMaxValue | null;
    note: string | null;
    autocomplete: string | null;
    title: string | null;
    mask: null | "us-phone" | "zipCode";
    meta: null | { [key in FieldMetaKeys]: string }[];
    niceName: string | null;
    dependency: null | {
        fieldCodeName: string;
        data: { [x: string]: string[] };
    };
    conditionalLogic: null | FieldConditionalLogic;
    styles?: {
        withTooltip?: boolean;
        perPage?: number;
        withSearch?: boolean;
    };
    fetchingFieldData?: boolean;
    origin: Origin;
}
export interface FormStep {
    title: string;
    description: string;
    buttonText: string;
    fields: Field[];
    id: number;
    TCPAMessage: string | undefined;
    includeTCPAMessage: boolean;
    tooltip: string | null;
    order: number;
}
export interface CompareHomePageInitState {
    activeStep: number;
    values: { [x: string]: string };
    steps: StepInterface[];
}

export interface IAccordionItem {
    id: number;
    title: string;
    content: string;
    link: string;
    question: string;
    answer: string;
}

export interface CategorySlide {
    id: number;
    imgPath: string;
    label: string;
    slug: string;
    children?: [];
}
export interface Tab {
    [x: string]: { label: string; slug: string };
}
export interface CarouselInitState {
    pages: number;
    currentPage: number;
    current_pos: number;
    threshold: number;
    numberOfItemsPerPage: number;
    carouselWidth: number;
}
export interface CarouselProps {
    id: string;
    slideComp: string;
    slides: GQLCategories[] | GQLOffers[];
    showDots: boolean;
    showArrows:
        | {
              fillArrowColor: string;
              arrowBg: string;
          }
        | undefined;
    large: number;
    mid: number;
    small: number;
    breakLarge: number;
    breakMid: number;
    qaSelector: string;
    reset: GQLCategories;
    arrowsPositionClasses: string;
    className: string;
    action: (val: GQLCategories) => void | undefined;
}

export type FieldTypes =
    | "text"
    | "hidden"
    | "email"
    | "number"
    | "tel"
    | "textarea"
    | "date"
    | "boolean"
    | "radio"
    | "checkbox"
    | "select"
    | "multiSelect"
    | "streetAddress"
    | "zipCodeExpandable"
    | "calculatedYears"
    | "zipCode"
    | "";

type AttributesKeys =
    | "scoreColor"
    | "scoreValue"
    | "scoreMsg"
    | "devices"
    | "promoCode"
    | "phoneNumber"
    | "phoneNumberMessage"
    | "discountValue"
    | "sellingPoint";

type HiddenAttributesKeys =
    | "color"
    | "displayOnExitWindow"
    | "showOnThankYou"
    | "scoreColor"
    | "discountColor"
    | "promoCode"
    | "phoneNumber"
    | "forgroundButtonColor"
    | "backgroundButtonColor"
    | "sellingPointsCheckBoxColors"
    | "offerPriceColor"
    | "discountColor";

export type Languages = "en" | "es" | "ar" | "ko" | "zh";

export interface Meta {
    key: string;
    value: string;
}

type MinMaxValue =
    | { value: Date | null; type: "date" }
    | { value: string | null; type: "number" };

export interface Image {
    id: string;
    imageData?: string;
    mimeType: string;
    size: number;
    originalName: string;
    fullPath: string;
    thumbnails: [
        {
            path: string;
            dimension: string;
        },
    ];
}

export interface Option {
    id: number | undefined;
    label: string;
    value: string | number;
    icon?: null | Pick<Image, "fullPath">;
}

export interface FieldConditionalLogic {
    isActive: true;
    action: "show" | "hide";
    operator: "AND" | "OR";
    requiredWhenShown: boolean;
    show?: boolean;
    conditions: {
        codeName: string;
        operator: "equal" | "notEqual";
        value: string;
    }[];
}

export interface FieldMeta {
    key: string;
    value: string;
}

export type FieldMetaKeys =
    | "tooltip"
    | "fieldDescription"
    | "reverseOptions"
    | "customLabel"
    | "stateRegulation"
    | "expandable";

export interface ValidationErrors {
    type: string;
    value: string | undefined;
    validationSkipped?: boolean;
}
export interface LookupOrVariationItem {
    id: number;
    label: string;
    value: string;
    icon?: null;
}

export interface Buyer {
    id?: number;
    label?: string;
    order?: number;
    numberRate?: number;
    stringRate?: string;
    featured?: string;
    imgPath?: string;
    lastCheck?: string;
    phoneNumber?: string;
    mainFeatures: {
        cost?: string;
        customer?: string;
        maintenance?: string;
    };
    pathLink?: string;
    title?: string;
    description?: string;
    infos?: string[];
    prices: string[];
    pros?: string[];
    cons?: string[];
    comparisonAttributes: {
        label: string;
        values: { label: string; value: boolean }[];
    }[];
    logo?: string;
}

export type Category = {
    id: number;
    name: string;
    slug: string;
    icon: Image | null;
    image: Image | null;
    title: string | null;
    slugAlias: string;
    crossSellingDomainFormId: string | null;
};

export interface ListingItem {
    id: number;
    slug: string;
    title: string;
    description: string;
    displayUrl: string;
    logo: Image | null;
    image: Image | null;
    features: string[];
    category: Category;
    attributes: { [key in AttributesKeys]: string };
    hiddenAttributes: { [key in HiddenAttributesKeys]: string };
    oldPrice: string;
    price: string;
    discount: string;
    currency: string;
    isPhoneNumber: boolean | undefined;
    phoneLabel: string | undefined;
    phoneValue: string | undefined;
    link: string | undefined;
    proxiedNumber: {
        phoneNumber: string;
        category: string;
        categoryId: number;
        categorySlug: string;
        ts: string;
        offerId: number;
    } | null;
}
export interface Blog {
    id: number;
    img: string;
    title: string;
    link: string;
    label: string;
    content: string;
    category: string;
    topThree: Buyer[];
}

export interface BreadcrumbItem {
    label: string;
    link: string;
    isActive?: boolean;
    showOnMobile?: boolean;
}

export interface ErrorsAfterSubmit {
    status: string;
    codeName: string;
    reason: string;
}

export interface SubmitLeadData {
    leadId: string | null;
    otpRequired: boolean;
    otpTime: number | undefined;
    results?:
        | {
              status: "rejected";
              codeName: string;
              reason: string;
          }[]
        | undefined;
}
export interface StepProps {
    step: FormStep;
    register: UseFormRegister<{}>;
    control: Control<FieldValues, any>;
    errors: object;
    stepNumber: number;
    currentStep: number;
    action: (data: any) => void;
    inputAction?: (data: boolean) => Promise<{
        data: SubmitLeadData | null;
        error: Error | null;
    }>;
    stepsLength: number;
    setValue: UseFormSetValue<{}>;
    watch: UseFormWatch<FieldValues>;
    formId: number;
    dependencies?: AllFieldsDependency;
    buttonLoading: boolean;
    isSingleField?: boolean;
    formErrors: FormErrors | null;
    isLastStep?: boolean;
    setError: (name: string, error: ErrorField | null) => void;
    firstRoundBuyers: IOne2OneBuyer[];
}

export interface ActiveForm {
    data: DomainForm;
    error: unknown;
}

export interface ErrorsFieldsAfterSubmitData {
    allErrorsFields: ErrorsAfterSubmit[];
    buttonLoading: boolean;
    stepsThatHaveErrors: Set<number>;
    register: UseFormRegister<{}>;
    setValue: UseFormSetValue<{}>;
    watch: UseFormWatch<FieldValues>;
    inputAction: (data: boolean) => Promise<{
        data: SubmitLeadData | null;
        error: Error | null;
    }>;
    formErrors: FormErrors | null;
    setError: (name: string, error: ErrorField | null) => void;
}

export interface ExpandableFieldMeta {
    [x: string]: {
        addressComponentType: string;
        ignoreField: string;
        contentType: string;
        fieldType?: string;
        content: string;
    };
}

export interface AllExpandableFieldsMeta {
    [formId: number]: ExpandableFieldMeta;
}

export interface InputProp {
    field: Field;
    register?: UseFormRegister<{}>;
    setValue?: UseFormSetValue<{}>;
    formId: number;
    watch?: UseFormWatch<FieldValues>;
    stateErrors?: FormErrors | null;
    errorsFromValidation?: boolean;
    inputAction?: (data: boolean) => Promise<{
        data: SubmitLeadData | null;
        error: Error | null;
    }>;
    setFieldChanged: Dispatch<React.SetStateAction<boolean>>;
    questionAsTitle: boolean | undefined;
    isFirstStep: boolean;
}

export interface ExpandableTabData {
    city: string;
    state: string;
    zipCode: string;
}

export interface ExpandableFieldValue {
    value: string;
    displayValue: string;
}

export interface AddressComponent {
    long_name: string;
    short_name: string;
    types: string[];
}

export interface FormattedAddressComponent {
    [x: string]: {
        long_name: string;
        short_name: string;
        types: string[];
    };
}

export interface SearchResult {
    address_components: AddressComponent[];
    formatted_address: string;
    geometry: {};
    partial_match: boolean;
    place_id: string;
    types: string[];
}

export interface AddressInfoResponse {
    data: {
        results: SearchResult[];
        status: string;
    };
}

export interface RenderFieldProps {
    fld: Field;
    formId: number;
    errorsFromValidation?: boolean;
    stateErrors?: FormErrors | null;
    setModalVisibility?: (update: React.SetStateAction<boolean>) => void;
    register: UseFormRegister<{}>;
    setValue: UseFormSetValue<{}>;
    watch: UseFormWatch<FieldValues>;
    inputAction?: (data: boolean) => Promise<{
        data: SubmitLeadData | null;
        error: Error | null;
    }>;
    action?: (data: any) => void;
    setFieldChanged: Dispatch<SetStateAction<boolean>>;
    isSingleField: boolean;
    hasTitle: boolean;
    isFirstStep: boolean;
    formErrors: FormErrors | null;
    setError: (name: string, error: ErrorField | null) => void;
}

export interface WorkingHoursType {
    id: number;
    isActive: boolean;
    timeZone: string;
    schedule: {
        name: string;
        isActive: boolean;
        time: { end: string; start: string }[];
    }[];
}
export interface TranslationsObject {
    [key: string]: string;
}

export interface LanguagesData {
    [x: string]: {
        iso: string;
        flag: string;
        label: string;
        direction: string;
    };
}

export interface VisitorInfo {
    isBot: boolean;
    token: string;
    country: string;
    region: string;
}

export interface CategoriesList {
    [key: string]: GQLCategories;
}

export interface ArgsType {
    category?: string;
    region?: string;
}

export type FilterQuestion =
    | {
          label: string;
          type: "radio" | "select" | "toggle";
          options?: { label: string; value: string }[];
          value: string;
          name: string;
          placeholder?: string | undefined;
          isInHomePage?: boolean | undefined;
          fieldType?: never;
          mask?: never;
          maxLength?: never;
      }
    | {
          label: string;
          type: "text";
          options?: never;
          value: string;
          name: string;
          placeholder?: string | undefined;
          isInHomePage?: boolean | undefined;
          fieldType: HTMLInputTypeAttribute;
          mask: "zipCode" | null;
          maxLength?: number | undefined;
      };
export interface ClickOffer {
    title: string;
    bulletedDescription: string[];
    logoUrl: string;
    brandName: string;
    slug: string;
    revenue: number | undefined;
}

export interface GenericErrorMessage {
    title: string;
    body: string;
}

export interface IField {
    codeName: string;
    value: string | number | boolean | string[];
    formId: number;
    valid?: boolean;
    changed?: boolean;
    loading?: boolean;
    displayLabel?: string;
    options?: LookupOrVariationItem[] | undefined;
    type?: string;
    id?: string;
}

export interface IOne2OneBuyer {
    buyerId: string;
    buyerName: string;
    displayName: string;
    logo: string;
    selected: boolean;
}
export interface FormInitState {
    formResponse: DomainForm | null;
    formId: number;
    currentStep: number;
    allErrorsField: ErrorsAfterSubmit[];
    domainFormId: number;
    timer: number;
    originalTimer: number;
    thankYouOffers: GQLOffers[];
    visitorInfo: VisitorInfo | null;
    fieldRegulations: Regulations | null;
    offerClicks: ClickOffer[];
    listRequestId: string;
    formErrors: FormErrors | null;
    leadId: string;
    operationId: string;
    buyers: IOne2OneBuyer[];
    firstRoundBuyers: IOne2OneBuyer[];
    secondRoundBuyers: IOne2OneBuyer[];
    tempSecondRoundBuyers: IOne2OneBuyer[];
    expandableField: AllExpandableFieldsMeta | null;
    tcpaMessage: string;
    stepToShowBackBtn: number;
    submitButtonDisabled: boolean;
    domainConsent: boolean;
    fieldsState: {
        [formId: number]: {
            [codeName: string]: IField;
        };
    };

    isLoading: boolean;
    offerClicksLoading: boolean;
    buttonLoading: boolean;
    apiCallInProgress: boolean;

    showOtpError: boolean;
    showOtp: boolean;
    showGenericErrorMessage: GenericErrorMessage | null;
    showInvalidFieldsFrom: boolean;
    showThankYou: boolean;
    modalVisibility: boolean;
    showBuyersList: boolean;
    isOneToOneConsent?: boolean;
}

export interface SetStepActionPayload {
    errors: FormErrors | null;
}

export enum FormActionTypes {
    SET_STEP = "SET_STEP",
    SET_ERRORS_AFTER_SUBMIT = "SET_ERRORS_AFTER_SUBMIT",
    SET_BUTTON_LOADING = "SET_BUTTON_LOADING",
    SET_BUTTON_DISABLED = "SET_BUTTON_DISABLED",
    SET_FORM_RESPONSE = "SET_FORM_RESPONSE",
    SET_FORM_ID = "SET_FORM_ID",
    SET_ORIGINAL_TIME = "SET_ORIGINAL_TIME",
    SET_OTP_DATA = "SET_OTP_DATA",
    SET_DOMAIN_FORM_ID = "SET_DOMAIN_FORM_ID",
    SET_IS_LOADING = "SET_IS_LOADING",
    SET_THANK_YOU_DATA = "SET_THANK_YOU_DATA",
    SET_LEAD_ID = "SET_LEAD_ID",
    SET_OPERATION_ID = "SET_OPERATION_ID",
    SET_SHOW_OTP_ERROR = "SET_SHOW_OTP_ERROR",
    SET_SHOW_INVALID_FIELDS = "SET_SHOW_INVALID_FIELDS",
    SET_SHOW_THANK_YOU = "SET_SHOW_THANK_YOU",
    SET_ERRORS_AFTER_SUBMIT_DATA = "SET_ERRORS_AFTER_SUBMIT_DATA",
    SET_THANK_YOU_OFFERS = "SET_THANK_YOU_OFFERS",
    SET_GENERIC_ERROR_MESSAGE = "SET_GENERIC_ERROR_MESSAGE",
    SET_OFFERS_CLICK_DATA = "SET_OFFERS_CLICK_DATA",
    SET_REGULATIONS = "SET_REGULATIONS",
    REMOVE_ALL_ERRORS_FIELDS = "REMOVE_ALL_ERRORS_FIELDS",
    SET_EXPANDABLE_FIELD = "SET_EXPANDABLE_FIELD",
    INIT_FORM = "INIT_FORM",
    SET_ERRORS = "SET_ERRORS",
    SET_API_CALL_IN_PROGRESS = "SET_API_CALL_IN_PROGRESS",
    SET_TCPA_MESSAGE = "SET_TCPA_MESSAGE",
    SET_STEP_TO_SHOW_BACK_BTN = "SET_STEP_TO_SHOW_BACK_BTN",
    CLOSE_FORM_MODAL = "CLOSE_FORM_MODAL",
    SET_MODAL_VISIBILITY = "SET_MODAL_VISIBILITY",
    SET_BUYERS = "SET_BUYERS",
    SET_FIRST_ROUND_BUYERS = "SET_FIRST_ROUND_BUYERS",
    SET_SECOND_ROUND_BUYERS = "SET_SECOND_ROUND_BUYERS",
    SET_TEMP_SECOND_ROUND_BUYERS = "SET_TEMP_SECOND_ROUND_BUYERS",
    SET_SHOW_BUYERS_LIST = "SET_SHOW_BUYERS_LIST",
    IS_ONE_TO_ONE_CONSENT = "IS_ONE_TO_ONE_CONSENT",
    SET_DOMAIN_CONSENT = "SET_DOMAIN_CONSENT",
}

export interface SetOtpData {
    originalTimer: number;
    buttunLoading: boolean;
    showOtp: boolean;
    showInvalidFieldsFrom: boolean;
    showThankYou: boolean;
}

export interface SetShowThankYou {
    leadId: string;
    showInvalidFieldsFrom: boolean;
    showOtpError: boolean;
    showThankYou: boolean;
    currentStep: number;
    buttonLoading: boolean;
}

export interface SetErrorsAfterSubmitData {
    buttonLoading: boolean;
    showInvalidFieldsFrom: boolean;
    showThankYou: boolean;
    allErrorsField: ErrorsAfterSubmit[];
}

export interface SetOfferClicksData {
    offerClicksLoading: boolean;
    offerClicks: ClickOffer[];
    listRequestId: string;
}

export interface InitForm {
    isLoading: boolean;
    showThankYou: boolean;
    currentStep: number;
    domainFormId: number;
    formId: number;
    formResponse: DomainForm | null;
    errors: FormErrors;
}

export interface CloseFormModal {
    currentStep: number;
    tcpaMessage: string;
    leadId: string;
    errors: FormErrors;
    stepToShowBackBtn: number;
}

export type FormReducerActions =
    | {
          type: FormActionTypes.SET_BUTTON_LOADING;
          payload: boolean;
      }
    | {
          type: FormActionTypes.SET_STEP;
          payload: number;
      }
    | {
          type: FormActionTypes.SET_FORM_RESPONSE;
          payload: DomainForm | null;
      }
    | {
          type: FormActionTypes.SET_FORM_ID;
          payload: number;
      }
    | {
          type: FormActionTypes.SET_OPERATION_ID;
          payload: string;
      }
    | {
          type: FormActionTypes.SET_ORIGINAL_TIME;
          payload: number;
      }
    | {
          type: FormActionTypes.SET_OTP_DATA;
          payload: SetOtpData;
      }
    | {
          type: FormActionTypes.SET_DOMAIN_FORM_ID;
          payload: number;
      }
    | {
          type: FormActionTypes.SET_IS_LOADING;
          payload: boolean;
      }
    | {
          type: FormActionTypes.SET_THANK_YOU_DATA;
          payload: SetShowThankYou;
      }
    | {
          type: FormActionTypes.SET_LEAD_ID;
          payload: string;
      }
    | {
          type: FormActionTypes.SET_SHOW_OTP_ERROR;
          payload: boolean;
      }
    | {
          type: FormActionTypes.SET_SHOW_INVALID_FIELDS;
          payload: boolean;
      }
    | {
          type: FormActionTypes.SET_SHOW_THANK_YOU;
          payload: boolean;
      }
    | {
          type: FormActionTypes.SET_ERRORS_AFTER_SUBMIT;
          payload: ErrorsAfterSubmit[];
      }
    | {
          type: FormActionTypes.SET_ERRORS_AFTER_SUBMIT_DATA;
          payload: SetErrorsAfterSubmitData;
      }
    | {
          type: FormActionTypes.SET_THANK_YOU_OFFERS;
          payload: GQLOffers[];
      }
    | {
          type: FormActionTypes.SET_GENERIC_ERROR_MESSAGE;
          payload: GenericErrorMessage | null;
      }
    | {
          type: FormActionTypes.SET_OFFERS_CLICK_DATA;
          payload: SetOfferClicksData;
      }
    | {
          type: FormActionTypes.SET_REGULATIONS;
          payload: Regulations | null;
      }
    | {
          type: FormActionTypes.REMOVE_ALL_ERRORS_FIELDS;
          payload: [];
      }
    | {
          type: FormActionTypes.SET_EXPANDABLE_FIELD;
          payload: AllExpandableFieldsMeta | null;
      }
    | {
          type: FormActionTypes.INIT_FORM;
          payload: InitForm;
      }
    | {
          type: FormActionTypes.SET_ERRORS;
          payload: FormErrors;
      }
    | {
          type: FormActionTypes.SET_API_CALL_IN_PROGRESS;
          payload: boolean;
      }
    | {
          type: FormActionTypes.SET_TCPA_MESSAGE;
          payload: string;
      }
    | {
          type: FormActionTypes.SET_STEP_TO_SHOW_BACK_BTN;
          payload: number;
      }
    | {
          type: FormActionTypes.CLOSE_FORM_MODAL;
          payload: CloseFormModal;
      }
    | {
          type: FormActionTypes.SET_MODAL_VISIBILITY;
          payload: boolean;
      }
    | {
          type: FormActionTypes.SET_SHOW_BUYERS_LIST;
          payload: boolean;
      }
    | {
          type: FormActionTypes.SET_BUYERS;
          payload: IOne2OneBuyer[];
      }
    | {
          type: FormActionTypes.SET_FIRST_ROUND_BUYERS;
          payload: IOne2OneBuyer[];
      }
    | {
          type: FormActionTypes.SET_SECOND_ROUND_BUYERS;
          payload: IOne2OneBuyer[];
      }
    | {
          type: FormActionTypes.SET_TEMP_SECOND_ROUND_BUYERS;
          payload: IOne2OneBuyer[];
      }
    | {
          type: FormActionTypes.IS_ONE_TO_ONE_CONSENT;
          payload: boolean | undefined;
      }
    | {
          type: FormActionTypes.SET_BUTTON_DISABLED;
          payload: boolean;
      }
    | {
          type: FormActionTypes.SET_DOMAIN_CONSENT;
          payload: boolean;
      };

export interface OpenSearchResult {
    _index: string;
    _id: string;
    _score: number;
    _source: {
        modelType: "posts" | "offers" | "categories" | "comparison";
        url: string;
        raw: {
            title: string;
            overview?: string;
            body?: string;
        };
    };
    highlight: {
        "raw.title": string[];
    };
}

export interface AccordionData {
    title: string;
    content: string;
    id: number;
}

export interface ICategoryByIdPageProps {
    category: GQLCategories;
    domainOffers: GQLDomainOffers[];
    offers: GQLOffers[];
    offersListTextBlock: GQLTextBlocks | string | null;
    categoryFAQs:
        | {
              id: number | null | undefined;
              title: string | null | undefined;
              content: string | null | undefined;
          }[]
        | undefined;
    topics:
        | {
              title: string;
              link: string;
          }[]
        | undefined;
    recentPosts:
        | {
              title: string | null | undefined;
              link: string;
          }[]
        | undefined;
}

export interface ICategoryPageSharedProps {
    dkis: GQLDkis[];
    textBlock: GQLTextBlocks;
    categoryLabel: string;
    categoryName: string;
    topBuyers: GQLOffers[];
    activeTab: string;
    existModalOffers: GQLOffers[];
    url?: GQLUrls;
}

export interface IOfferPageSharedProps {
    activeTab: string;
}

export interface IReviewOfferPageProps {
    category: GQLCategories;
    offers: GQLOffers[];
    dkis: GQLDkis[];
    offer: GQLOffers;
}

export interface IBestOverallPageProps {
    category: GQLCategories;
    offers: GQLOffers[];
}
export interface ISharedCompareProps {
    url: GQLUrls;
    domainOffers: GQLDomainOffers[];
}

export interface ICompareCategoryProps {
    category: GQLCategories;
    // domainOffers: GQLOffers[];
    allCategoryOffers?: GQLOffers[];
    textBlocks?: GQLTextBlocksQuery;
}

export interface ICategoryArticlesPageProps {
    category: GQLCategories;
    currentPage: number;
    total: number;
    articles: GQLPosts[];
    isNews: boolean;
}

export interface IVendorComparePageProps {
    category: GQLCategories;
    offer: GQLOffers;
    dkis: GQLDkis[];
    vendorsToCompare: GQLOffers[];
}

export interface IBlogPostPageProps {
    category?: GQLCategories | undefined;
    post: GQLPosts;
    relatedPosts: { title: string; link: string }[] | undefined;
    dkis: GQLDkis[];
}

export interface ICategoriesPageProp {
    categories: GQLCategories[];
    domainOffers: DomainOffer[];
}

export interface ISitemapPageProps {
    posts: GQLPosts[];
    offers: GQLOffers[];
    categories: GQLCategories[];
}

export interface IDomainInfo {
    allTranslations?: Maybe<Array<Maybe<GQLTranslations>>>;
    allowedLanguages?: Maybe<Scalars["JSON"]>;
    analyticsCodeId?: Maybe<Scalars["String"]>;
    cacheSettings?: Maybe<Scalars["JSON"]>;
    categories?: Maybe<Array<Maybe<GQLCategories>>>;
    clickyCodeId?: Maybe<Scalars["String"]>;
    config?: Maybe<Scalars["JSON"]>;
    createdAt?: Maybe<Scalars["String"]>;
    defaultOffers?: Maybe<Array<Maybe<GQLOffers>>>;
    description?: Maybe<Scalars["String"]>;
    domainCategories?: Maybe<Array<Maybe<GQLDomainCategories>>>;
    domainForms?: Maybe<Array<Maybe<GQLDomainForms>>>;
    domainOffers?: Maybe<Array<Maybe<GQLDomainOffers>>>;
    enableChatWidget?: Maybe<Scalars["Boolean"]>;
    enableJornayaId?: Maybe<Scalars["Boolean"]>;
    enableTrustedForm?: Maybe<Scalars["Boolean"]>;
    facebookConversionAPISettings?: Maybe<Scalars["JSON"]>;
    facebookSiteVerificationCode?: Maybe<Scalars["String"]>;
    favicon?: Maybe<GQLAttachments>;
    faviconId?: Maybe<Scalars["String"]>;
    forms?: Maybe<Array<Maybe<GQLForms>>>;
    googleSiteVerificationCode?: Maybe<Scalars["String"]>;
    gtmId?: Maybe<Scalars["String"]>;
    holdingRules?: Maybe<Scalars["JSON"]>;
    id?: Maybe<Scalars["Int"]>;
    isActive?: Maybe<Scalars["Boolean"]>;
    isDeleted?: Maybe<Scalars["Boolean"]>;
    isInspectorEnabled?: Maybe<Scalars["Boolean"]>;
    listings?: Maybe<Array<Maybe<GQLListings>>>;
    logo?: Maybe<GQLAttachments>;
    logoId?: Maybe<Scalars["String"]>;
    menus?: Maybe<Array<Maybe<GQLMenus>>>;
    metatags?: Maybe<Array<Maybe<GQLDomainMetatags>>>;
    name?: Maybe<Scalars["String"]>;
    niceName?: Maybe<Scalars["String"]>;
    offers?: Maybe<Array<Maybe<GQLDomainOffers>>>;
    otpVerificationCriteria?: Maybe<Scalars["JSON"]>;
    posts?: Maybe<Array<Maybe<GQLPosts>>>;
    recaptchaConfig?: Maybe<Scalars["JSON"]>;
    routes?: Maybe<Array<Maybe<GQLDomainRoutes>>>;
    secondaryLogo?: Maybe<GQLAttachments>;
    secondaryLogoId?: Maybe<Scalars["String"]>;
    settings?: Maybe<Scalars["JSON"]>;
    slogan?: Maybe<Scalars["String"]>;
    templateConfig?: Maybe<Scalars["JSON"]>;
    title?: Maybe<Scalars["String"]>;
    translations?: Maybe<Array<Maybe<GQLTranslations>>>;
    twitterPixel?: Maybe<Scalars["String"]>;
    type?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["String"]>;
    userId?: Maybe<Scalars["String"]>;
}

export interface ICustomDomain {
    domain: IDomainInfo;
}

export type MetaPagesKeys = "Categories";
